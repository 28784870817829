/** types */
import type { TImageFeatured } from '~/src/types/common'

/** utils */
import { cloudinaryBuildRoute } from '~/src/utils/cloudinary'

const HOME_HERO_IMAGE_DESKTOP =
  'v1734046885/assets/home/home-hero-desktop_lcnwhqf.png'
const HOME_HERO_IMAGE_TABLET =
  'v1733501414/assets/home/home-hero-tablet_lcnwhq.png'
const HOME_HERO_IMAGE_MOBILE =
  'v1734046574/assets/home/home-hero-mobile_wi88e7.png'

export const HOME_HERO_IMAGES: TImageFeatured = {
  EXTRA_LARGE: cloudinaryBuildRoute(HOME_HERO_IMAGE_DESKTOP, ['w_2560']),
  LARGE: cloudinaryBuildRoute(HOME_HERO_IMAGE_DESKTOP, ['w_1920']),
  NORMAL: cloudinaryBuildRoute(HOME_HERO_IMAGE_TABLET, ['w_1440']),
  MEDIUM: cloudinaryBuildRoute(HOME_HERO_IMAGE_TABLET, ['w_1280']),
  DEFAULT: cloudinaryBuildRoute(HOME_HERO_IMAGE_TABLET, ['w_1024']),
  SMALL: cloudinaryBuildRoute(HOME_HERO_IMAGE_MOBILE, [
    'w_520',
    'h_479',
    'c_fill',
  ]),
  TINY: cloudinaryBuildRoute(HOME_HERO_IMAGE_MOBILE, [
    'w_425',
    'h_479',
    'c_fill',
  ]),
}

const MODIFIERS = ['c_scale', 'c_fill']

export const AWARD_BADGE_URL = cloudinaryBuildRoute(
  'v1724447029/assets/home/110e3a8c58c83f33e0ace57269a9220f_hhunpg.png',
  ['w_460'],
  true,
  ['c_fit'],
)

const EARTH_BANNER_IMAGE = 'v1678804229/assets/home/VideoBannerHome_npgzv9.jpg'

export const EARTH_BANNER_IMAGES: TImageFeatured = {
  EXTRA_LARGE: cloudinaryBuildRoute(
    EARTH_BANNER_IMAGE,
    ['w_2560'],
    false,
    MODIFIERS,
  ),
  LARGE: cloudinaryBuildRoute(EARTH_BANNER_IMAGE, ['w_1920'], false, MODIFIERS),
  NORMAL: cloudinaryBuildRoute(
    EARTH_BANNER_IMAGE,
    ['w_1440'],
    false,
    MODIFIERS,
  ),
  MEDIUM: cloudinaryBuildRoute(
    EARTH_BANNER_IMAGE,
    ['w_1280'],
    false,
    MODIFIERS,
  ),
  DEFAULT: cloudinaryBuildRoute(
    EARTH_BANNER_IMAGE,
    ['w_1024'],
    false,
    MODIFIERS,
  ),
  SMALL: cloudinaryBuildRoute(EARTH_BANNER_IMAGE, ['w_768'], false, [
    ...MODIFIERS,
    'so_-40',
  ]),
  TINY: cloudinaryBuildRoute(EARTH_BANNER_IMAGE, ['w_425'], false, [
    ...MODIFIERS,
    'so_-40',
  ]),
}

export const COPPER_IMAGES = {
  BACKGROUND_1:
    'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,f_auto,w_464,q_auto/v1741643196/assets/market/copper/c9d774068777c39553e9b627dbab5286_dfftll.jpg',
  BACKGROUND_2:
    'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,f_auto,w_461,q_auto/v1741643196/assets/market/copper/image_324_ltk5fw.png',
  SERVICES_LOGO:
    'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,f_auto,h_30,q_auto/v1741643822/assets/market/copper/bm-logo_ztz0xm.png',
}
