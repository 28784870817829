<template>
  <div
    class="relative mx-auto flex h-full min-h-[523px] overflow-hidden transition-all duration-700 lg:max-h-[clamp(420px,601px,601px)] lg:min-h-[601px]"
  >
    <div id="bgImage" class="relative h-full min-h-[523px] grow lg:h-[601px]">
      <ImageFeatured
        fetch-priority="high"
        alt="Battery Home Hero"
        :images="HOME_HERO_IMAGES"
        classes="h-full w-full min-h-[523px] lg:min-h-[clamp(420px,641px,601px)] object-cover lg:opacity-100"
      />

      <!-- Overlay -->
      <div
        class="bg-[linear-gradient(to,top,rgba(16,64,122,0) 97%, #001a39 69%] lg:bg-[linear-gradient(to,top,rgba(16,64,122,0) 167%, #001a39 119%] absolute left-0 top-0 h-full w-full opacity-45 bg-blend-soft-light md:block"
      />

      <div
        class="absolute bottom-0 left-0 right-0 top-0 mx-auto h-full max-w-content-container px-4 lg:px-12 2xl:px-0"
      >
        <KingsAwardEmblem
          class="absolute left-4 top-3 z-10 lg:left-auto lg:right-6 lg:top-6"
        />
        <div class="relative flex h-full w-full flex-col-reverse lg:block">
          <div
            class="flex h-full w-full flex-col gap-3 pt-[116px] text-white lg:gap-6 lg:pt-[90px] xl:mt-0"
          >
            <h2
              class="font-stretch-normal flex max-w-[948px] flex-col text-h1 font-bold not-italic !leading-[normal] tracking-normal md:text-balance lg:text-6xl lg:!leading-tight xl:text-[5rem] xl:!leading-[97.5px]"
            >
              Critical Minerals. Energy Supply Chains. From Mine to Grid.
            </h2>
            <div
              class="flex flex-wrap text-balance lg:max-w-[820px] lg:flex-col"
            >
              <p class="text-h5">
                Benchmark’s prices and data empower the energy transition: from
                supply chain contracts and security, to policy and capital
                deployment
              </p>
            </div>

            <div class="mt-8 flex lg:mt-0">
              <CommonButton
                variant="primary"
                extra-classes="!w-full sm:!w-auto"
                @click="$modal.open('contact')"
              >
                Contact Us
              </CommonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/** nuxt */
import { useNuxtApp } from 'nuxt/app'

/** components */
import CommonButton from '~/components/common/Button.vue'
import ImageFeatured from '~/src/components/common/images/ImageFeatured.vue'
import KingsAwardEmblem from '~/src/components/common/logos/KingsAwardEmblem.vue'

/** constants */
import { HOME_HERO_IMAGES } from '~/src/constants/home'

/** app */
const { $modal } = useNuxtApp()

onMounted(() => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})
</script>
