<script setup lang="ts">
import HSForm from '~/components/forms/HSForm.vue'

const config = useRuntimeConfig()
const formId = config.public.hubspotMineToGridFormId as string
</script>

<template>
  <div class="h-full px-8">
    <HSForm class="m2g-form h-full" :form-id="formId" @close="$emit('close')">
      <template #header>
        <div class="md:grid md:grid-cols-12 gap-x-6 mb-8">
          <div class="md:col-span-4">
            <img
              src="https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_200,c_fill/v1739807271/assets/home/BMxRho_logo_Primary_gd6la7.png"
              alt="title"
            />
            <img
              src="https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_250,c_fill/v1739807271/assets/home/Mine_To_Grid_Logo_Final_datnen.png"
              alt="hero"
            />
          </div>
          <div class="md:col-span-8 flex flex-col gap-1">
            <p>
              Following Benchmark’s landmark acquisition of industry leader in
              downstream data and intelligence, Rho Motion, we have developed a
              roundtable video series to showcase the extended depth and breadth
              of our coverage from mine to grid.
            </p>
            <p>
              Register to gain access to our 360° coverage of energy transition
              supply chains.
            </p>
            <p class="mt-2 text-sm font-bold">
              Please provide your details and select which Mine to Grid video
              you would like to receive.
            </p>
          </div>
        </div>
      </template>
    </HSForm>
  </div>
</template>

<style>
.m2g-form .bm-custom-form .inputs-list.multi-container {
  @apply grid grid-cols-1 gap-y-1;
}

.m2g-form .bm-custom-form .legal-consent-container .hs-richtext:first-child {
  @apply mb-4;
}
</style>
