<script setup lang="ts">
import Modal from '~/components/common/Modal.vue'
import MineToGridForm from '~/components/forms/MineToGridForm.vue'

import { useModalStore } from '~/stores/modal'

const { close } = useModalStore()
</script>

<template>
  <Teleport to="body">
    <Modal
      modal-name="mineToGrid"
      modal-classes="h-full w-full max-w-mine-to-grid-form-container rounded-xl bg-white shadow-dp-01 max-h-[85vh] lg:max-h-[800px] relative"
      modal-content-classes="h-full py-8 overflow-y-auto grid"
      modal-header-classes="top-3 right-5 absolute !px-0 z-10"
      button-close-classes="bg-white p-2 rounded-full outline-none absolute top-0 right-0"
    >
      <template #content>
        <MineToGridForm @close="close('mineToGrid')" />
      </template>
    </Modal>
  </Teleport>
</template>
