<script setup lang="ts">
/** utils */
import { getEventsSinceLastYear, getUpcomingEvents } from '~/src/utils/events'
import { logError } from '~/utils/log-error'

/** queries */
import latestAnnouncementQuery from '~/queries/latest-announcement.gql'

/** constants */
import { TITLE_WEBSITE } from '~/src/constants/seo'

/** composables */
import { useSeo } from '~/composables/useSeo'

/** components */
import AnnouncementsBar from '~/components/home/AnnouncementsBar.vue'
import CarouselSection from '~/components/home/CarouselSection.vue'
import Hero from '~/src/components/pages/home/Hero.vue'
import Features from '~/components/home/Features.vue'
import EarthBanner from '~/src/components/pages/home/EarthBanner.vue'
import SupplyChain from '~/components/home/SupplyChain.vue'
import OurDivisionsSection from '~/components/OurDivisionsSection.vue'
import { useModalStore } from '~/stores/modal'
import { SHOW_HOME_COPPER_MODAL } from '~/src/constants/common'
import { format } from 'date-fns'
import CopperModal from '~/components/home/copper/CopperModal.vue'

const copperModalDate = format(new Date(2025, 5, 11), 'MMMM dd, yyyy')
const now = format(new Date(), 'MMMM dd, yyyy')

/** app */
const { $graphqlClient, $cookies, $bmAuth, $modal } = useNuxtApp()
const { getSeoInfo } = useSeo()
const seo = getSeoInfo({ title: TITLE_WEBSITE })
const { open: openModal } = useModalStore()
const hasCopperModal = $cookies.get(SHOW_HOME_COPPER_MODAL)

/** computed */
const loading = computed(() => status.value !== 'success')
const articles = computed(() => data.value?.articles)
const event = computed(() => data.value?.event)
const latestAnnouncement = computed(() => data.value?.latestAnnouncement)

const getArticles = async () => {
  const ITEMS_ARTICLES = 3
  const response = await $graphqlClient.getMembershipArticles({
    first: ITEMS_ARTICLES,
    showOnlyFeaturedPosts: true,
  })

  return response.data.memberships?.nodes || []
}

const getEvents = async () => {
  const events = await $graphqlClient.getEventsWithDateQuery()
  const eventsFiltered = events.filter(
    (event) => event.status === 'publish' && event.isFeatured,
  )
  const eventsSinceLastYear = getEventsSinceLastYear(eventsFiltered)
  const upcomingEvents = getUpcomingEvents(eventsSinceLastYear, true)

  if (upcomingEvents?.length === 0) return null

  return upcomingEvents.at(0)
}

const getLatestAnnouncement = async () => {
  try {
    const { data } = await $bmAuth.getPublicGraphqlClient().query({
      query: latestAnnouncementQuery,
    })
    return data
  } catch (error) {
    logError(error)
  }
}

/** lifecycle */
const { data, status } = useAsyncData(async () => {
  $modal.setInitialFields({})
  const [articlesResponse, eventsResponse, latestAnnouncementResponse] =
    await Promise.all([getArticles(), getEvents(), getLatestAnnouncement()])

  return {
    articles: articlesResponse,
    event: eventsResponse,
    latestAnnouncement: latestAnnouncementResponse,
  }
})

const showCopperModal = () => {
  const shouldShowCopperModal = copperModalDate !== now
  if (
    (hasCopperModal !== undefined && !hasCopperModal) ||
    import.meta.server ||
    !shouldShowCopperModal
  )
    return
  openModal('homeCopper')
}

onMounted(() => {
  showCopperModal()
})
useHead(seo)
</script>

<template>
  <div class="flex h-full w-full items-center justify-center">
    <div class="grow">
      <div data-testid="hero" class="relative h-full">
        <Hero />
        <ClientOnly>
          <AnnouncementsBar :latest-announcement="latestAnnouncement" />
        </ClientOnly>
        <CarouselSection
          :event="event"
          :articles="articles"
          :loading="loading"
        />
      </div>
      <div
        class="max-w-content-container px-small mx-auto mb-6 md:mb-0 lg:px-14 2xl:px-0 content-visibility"
      >
        <Features />
      </div>

      <EarthBanner class="my-6 md:mb-10 md:mt-7" />

      <h2
        class="max-w-content-container px-small text-surface-em-high mx-auto mb-3 text-2xl font-bold leading-8 lg:px-14 lg:text-3xl lg:leading-9 2xl:px-0 content-visibility"
      >
        Lithium ion batteries: Benchmark’s unique supply chain approach
      </h2>
      <div class="px-small md:px-0">
        <SupplyChain />
      </div>
      <div
        data-testid="our-divisions-section"
        class="max-w-content-container px-small py-big lg:px-big mx-auto 2xl:px-0 content-visibility"
      >
        <h3 class="mb-4 text-xl font-bold md:text-2xl lg:text-3xl">
          Our Divisions
        </h3>
        <OurDivisionsSection />
      </div>
    </div>

    <CopperModal data-testid="home-copper-modal" />
  </div>
</template>
