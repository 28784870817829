<template>
  <div
    class="flex h-full flex-col justify-between px-7 py-5 lg:py-7"
    data-testid="mine-to-grid-card"
    @click="open('mineToGrid')"
  >
    <div class="flex flex-col">
      <div>
        <img
          alt="mine-to-grid-header"
          src="https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_200,c_fill/v1739807271/assets/home/BMxRho_logo_Primary_gd6la7.png"
          class="h-full max-h-8 w-fit md:max-h-10"
        />
      </div>
      <img
        alt="mine-to-grid"
        src="https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_200,c_fill/v1739807271/assets/home/Mine_To_Grid_Logo_Final_datnen.png"
        class="m-[0_auto_0_0] h-full max-h-[76px] object-contain 2xl:max-h-[103px]"
      />
      <p class="text-sm font-semibold">
        A new video series providing 360° coverage of energy transition supply
        chains - from mine to grid.
      </p>
    </div>

    <Button variant="hero" class="hidden w-full md:inline-flex md:!w-fit">
      Sign up
    </Button>
  </div>
  <MineToGridModal v-if="mineToGrid" data-testid="mine-to-grid-modal" />
</template>

<script setup lang="ts">
import Button from '~/components/common/Button.vue'
import MineToGridModal from '~/components/home/mine-to-grid/MineToGridModal.vue'
import { useModalStore } from '~/stores/modal'

const { open, modals } = useModalStore()

const mineToGrid = computed(() => modals.mineToGridOpen)
</script>
