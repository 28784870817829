<template>
  <div
    ref="carousel"
    data-testid="hero-article"
    class="relative flex aspect-video w-full flex-1 max-w-full items-center justify-between overflow-hidden h-full lg:min-w-full"
    :style="$attrs.style"
    :class="$attrs.class"
    @mouseover="carouselRemove"
    @mouseleave="carouselReset"
  >
    <IconChevronLeft
      class="absolute left-2 text-p-500 w-2 cursor-pointer lg:w-3 lg:left-3 md:flex z-20 hidden"
      @click="backArticle"
    />
    <template v-if="type === 'capex-tracker'">
      <CarouselCapexTracker
        v-for="(capex, _index) of items"
        :key="capex.id"
        :material="capex.mineral"
        :value="capex.value"
        :image-url="capex.imageUrl"
        :index-maximum="indexMaximum()"
        :carousel-index="index"
        :article-index="_index"
      />
    </template>
    <div v-else class="flex h-full w-full flex-col">
      <CarouselSlice
        v-for="(article, _index) of items"
        :key="article.id"
        :article="article"
        :index-maximum="indexMaximum()"
        :carousel-index="index"
        :article-index="_index"
      >
        <template #points>
          <CarouselPoints
            class="z-20"
            :items="itemStates"
            @go-to-index="goToArticle"
          />
        </template>
      </CarouselSlice>
    </div>
    <IconChevronRight
      class="absolute right-2 z-20 lg:right-5 w-2 cursor-pointer lg:w-3 hidden md:flex text-p-500"
      @click="nextArticle"
    />
  </div>
</template>

<script setup lang="ts">
/** packages */
import { defineAsyncComponent } from 'vue'

/** components */
import CarouselPoints from '~/components/carousel/Points.vue'
import CarouselSlice from '~/components/carousel/Slice.vue'
import type { TCommon } from '~/src/types/common'
const IconChevronLeft = defineAsyncComponent(
  () => import('~/assets/icons/chevron-left.svg'),
)
const IconChevronRight = defineAsyncComponent(
  () => import('~/assets/icons/chevron-right.svg'),
)
const CarouselCapexTracker = defineAsyncComponent(
  () => import('~/components/carousel/CapexTracker.vue'),
)

/** props */
const props = withDefaults(
  defineProps<{
    items: TCommon[]
    type?: string
  }>(),
  {
    items: () => [],
    type: 'default',
  },
)

/** refs */

const carousel = ref<HTMLElement | null>(null)
const index = ref(0)
const timer = ref(3000)
const interval = ref(null)
const touchX = ref(0)

/** computed */
const articleActive = computed(() => {
  return props.items[index.value] || { id: index.value }
})

const itemStates = computed(() => {
  return props.items.map((_article) => {
    return {
      id: _article.id,
      active: _article.id === articleActive.value.id,
    }
  })
})

/** methods */

const touchInitialize = (_event: TouchEvent) => {
  touchX.value = _event.touches[0].clientX ?? 0
}

const touchEnd = (_event: TouchEvent) => {
  const clientX = _event.changedTouches[0].clientX
  if (clientX > touchX.value) {
    backArticle()
  }
  if (clientX < touchX.value) {
    nextArticle()
  }
}

const carouselInitialize = () => {
  interval.value = setInterval(() => nextArticle(), timer.value)
}

const indexMaximum = () => {
  return props.items.length - 1 || 0
}

const carouselRemove = () => clearInterval(interval.value)

const carouselReset = () => {
  carouselRemove()
  carouselInitialize()
}

const nextArticle = () => {
  const nextIndex = index.value + 1
  if (nextIndex > indexMaximum()) {
    index.value = 0
  } else {
    index.value = nextIndex
  }
}

const backArticle = () => {
  const backIndex = index.value - 1
  if (backIndex < 0) {
    index.value = props.items.length - 1
  } else {
    index.value = backIndex
  }
}

const goToArticle = (_index: number) => (index.value = _index)

/** lifecycle */
onMounted(() => {
  try {
    carousel.value?.addEventListener('touchstart', touchInitialize, {
      passive: true,
    })
    carousel.value?.addEventListener('touchend', touchEnd, {
      passive: true,
    })
  } finally {
    carouselInitialize()
  }
})

onUnmounted(() => carouselRemove())
</script>
