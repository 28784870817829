<script setup lang="ts">
import Modal from '~/components/common/ModalV2.vue'
import ImageCommon from '~/src/components/common/images/ImageCommon.vue'
import { COPPER_IMAGES } from '~/src/constants/home'
import StarIcon from '~/assets/icons/star-7.svg'
import Button from '~/components/common/Button.vue'
import { useModalStore } from '~/stores/modal'
import IconCopper from '~/assets/icons/sidebar/Copper.svg'
import { SHOW_HOME_COPPER_MODAL } from '~/src/constants/common'

const { $cookies } = useNuxtApp()
const modalStore = useModalStore()
const { close } = modalStore

const isOpen = computed(() => modalStore.modals.homeCopperOpen)

const closeModal = () => {
  close('homeCopper')
  $cookies.set(SHOW_HOME_COPPER_MODAL, false)
}
</script>

<template>
  <Modal
    name="homeCopper"
    modal-classes="h-full w-full max-w-[985px] bg-white max-h-fit p-0"
    content-classes="h-full overflow-y-auto grid"
    button-close-classes="bg-white p-2 outline-none absolute"
    :is-open="isOpen"
    @close="closeModal"
  >
    <div class="flex flex-col md:flex-row">
      <div
        class="relative h-full min-h-[9.313rem] w-full pt-14 md:w-1/2 md:pt-0"
      >
        <div
          class="absolute left-0 top-0 aspect-video h-full w-full object-bottom md:aspect-auto"
        >
          <ImageCommon
            fetch-priority="auto"
            :src="COPPER_IMAGES.BACKGROUND_2"
            alt="Copper"
            classes="object-cover w-full h-full"
          />
        </div>

        <div
          class="absolute left-0 top-4 z-10 flex w-[6.125rem] items-center gap-2 rounded-br rounded-tr bg-p-500 px-3 py-[0.375rem] text-h6 md:top-10"
        >
          <StarIcon class="h-6 w-6" />
          NEW
        </div>
        <!-- Overlay -->
        <div class="absolute left-0 top-0 h-full w-full bg-black/20" />
        <!-- Content -->
        <div
          class="relative flex h-full flex-col justify-end px-4 py-4 text-white md:justify-center md:px-10 md:py-0"
        >
          <ImageCommon
            fetch-priority="auto"
            :src="COPPER_IMAGES.SERVICES_LOGO"
            alt="Benchmark Events"
            classes="h-5 md:h-[1.875rem] w-auto object-contain object-left"
          />
          <div
            class="flex items-center gap-4 md:flex-col md:items-start md:gap-3"
          >
            <p class="mt-3 text-h2 xl:text-mega">Copper Service</p>
            <IconCopper class="size-10 lg:size-18 [&>path]:stroke-[0.7]" />
          </div>
        </div>
      </div>

      <div
        class="flex h-full flex-col justify-center px-4 py-10 pb-6 pt-4 md:w-1/2 md:px-14 md:pb-24 md:pt-24"
      >
        <h1 class="mb-4 text-h3 md:mb-10 md:text-h1">Exclusive access to:</h1>
        <ul
          class="mb-9 grid list-inside gap-4 text-paragraph-sm md:mb-16 md:gap-5 md:text-paragraph-xxl"
        >
          <li class="flex items-start gap-3">
            <span
              class="mt-2 inline-block size-[0.375rem] shrink-0 rounded-full bg-p-500 md:mt-1 md:size-3"
            ></span>
            Unparalleled supply and demand data, broken down by end-use segment,
            with forecasts out to 2050 across the full value chain
          </li>
          <li class="flex items-start gap-3">
            <span
              class="mt-2 inline-block size-[0.375rem] shrink-0 rounded-full bg-p-500 md:mt-1 md:size-3"
            ></span>
            Critical industry developments with our weekly Copper Briefing sent
            direct to your inbox
          </li>
          <li class="flex items-start gap-3">
            <span
              class="mt-2 inline-block size-[0.375rem] shrink-0 rounded-full bg-p-500 md:mt-1 md:size-3"
            ></span>
            Support and additional insights from our dedicated team of copper
            analysts
          </li>
        </ul>
        <Button
          link="/copper"
          variant="clean"
          class="!w-full max-w-[16rem] self-center text-h7 md:max-w-full"
          @click="closeModal"
        >
          Find Out More
        </Button>
      </div>
    </div>
  </Modal>
</template>
