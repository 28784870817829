<template>
  <div
    class="mx-auto grid h-full w-full gap-3 px-4 pt-6 md:max-w-content-container md:grid-cols-2 md:gap-4 lg:px-14 xl:grid-cols-3 2xl:px-0"
  >
    <template v-for="card in cards" :key="card.id">
      <div
        :class="[
          'hero-event bg-s-300 relative h-[234px] md:h-[290px] w-full overflow-hidden rounded-xl lg:w-full',
          card.contentClasses ?? '',
        ]"
      >
        <transition v-if="loading" name="swipe">
          <CarouselDefault :type="card.id" />
        </transition>
        <component :is="card.component" v-else v-bind="card.props" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
/** components */
import CarouselDefault from '~/components/home/CarouselDefault.vue'
import CarouselHero from '~/components/carousel/Hero.vue'
import HomeHeroEvent from '~/components/home/HeroEvent.vue'
import MineToGridCard from '~/components/home/mine-to-grid/MineToGridCard.vue'
import CopperCard from '~/components/home/copper/CopperCard.vue'

/** types */
import type { TArticleNode } from '~/src/types/wordpress-content'
import type { TEvent } from '~/src/types/events'

import { capexTrackerData } from '~/constants/capex-tracker-data'
import type { TCommon } from '~/src/types/common'

const { $config } = useNuxtApp()

const props = defineProps<{
  loading: boolean
  event?: TEvent | null
  articles: TArticleNode[]
}>()

type TCarouselCard = {
  id: string
  component: TCommon
  props?: TCommon
  contentClasses?: string
}

const cards: TCarouselCard[] = [
  {
    id: 'event',
    component: HomeHeroEvent,
    props: {
      event: props.event,
      loading: true,
    },
  },
  {
    id: 'source',
    component: CopperCard,
  },
  {
    id: 'capex-tracker',
    component: CarouselHero,
    props: {
      items: capexTrackerData,
      type: 'capex-tracker',
    },
    contentClasses:
      'bg-s-100 border border-grey-600 md:col-span-2 xl:col-span-1',
  },
]

if ($config.public.widgets.mineToGrid) {
  cards.pop()

  cards.push({
    id: 'mine-to-grid',
    component: MineToGridCard,
    contentClasses:
      'bg-s-100 border border-grey-600 md:col-span-2 xl:col-span-1',
  })
}
</script>
