<script setup lang="ts">
/** components */
import ButtonCommon from '~/components/common/Button.vue'
import ImageCommon from '~/src/components/common/images/ImageCommon.vue'

/** utils */
import { useScreenSize } from '~/composables/useScreenSize'
import { formatEventDateRange } from '~/src/utils/events'

/** types */
import type { TEvent } from '~/src/types/events'

/** constants */
import { CLOUDINARY_IMAGE_SIZE } from '~/src/constants/cloudinary'
import { EVENTS_FALLBACK_IMAGE, EVENTS_LOGO } from '~/src/constants/events'

/** app */
const { $cloudinary } = useNuxtApp()
const $router = useRouter()
const { isMobile } = useScreenSize()

/** props */
const props = withDefaults(
  defineProps<{
    event: TEvent | null
    loading: boolean
  }>(),
  {
    event: null,
    loading: true,
  },
)

/** computed */
const eventValid = computed(() => props.event?.title && props.event?.url)
const formattedDateRange = computed(() => {
  return formatEventDateRange(props.event)
})
const formattedLocation = computed(() =>
  eventValid.value && props.event?.location ? `${props.event?.location}` : '',
)
const eventPath = computed(() =>
  eventValid.value ? props.event.url : '/events',
)
const eventImage = computed(() => {
  if (!eventValid.value || !props.event.backgroundImage) {
    return EVENTS_FALLBACK_IMAGE
  }
  return $cloudinary.resize(
    props.event.backgroundImage,
    CLOUDINARY_IMAGE_SIZE.HERO_IMAGE,
  )
})
const eventTitle = computed(() =>
  eventValid.value
    ? props.event.title
    : 'There are no upcoming events at the moment',
)
const isAbsoluteLink = computed(() => {
  if (!eventPath.value) return false
  return (
    eventPath.value.startsWith('https') || eventPath.value.startsWith('http')
  )
})

const handlerClickMobile = () => {
  if (isMobile.value) {
    if (isAbsoluteLink.value) {
      window.open(eventPath.value, '_blank')
    } else {
      $router.push(eventPath.value)
    }
  }
}
</script>

<template>
  <div data-testid="hero-event" class="h-full" @click="handlerClickMobile">
    <div class="absolute top-0 aspect-video size-full md:aspect-auto">
      <ImageCommon
        fetch-priority="auto"
        :src="eventImage"
        :alt="eventTitle"
        classes="size-full min-w-full object-cover min-h-full"
        :height="144"
        :width="405"
      />
    </div>

    <!-- Overlay -->
    <div class="absolute left-0 top-0 h-full w-full bg-black/30" />

    <!-- Content -->
    <div
      class="relative flex h-full flex-col items-center justify-between p-5 text-white md:aspect-auto md:items-start md:px-8 md:py-5 lg:px-10 lg:py-7"
    >
      <div class="flex flex-col justify-start">
        <ImageCommon
          fetch-priority="auto"
          :src="EVENTS_LOGO"
          alt="Benchmark Events"
          classes="h-6 lg:h-5 w-auto object-contain object-left mb-2"
        />
        <NuxtLink
          no-prefetch
          :to="eventPath"
          :external="isAbsoluteLink"
          :target="isAbsoluteLink ? '_blank' : '_self'"
          class="mb-5 text-2xl font-bold leading-none md:mb-7 md:text-xl"
        >
          {{ eventTitle }}
        </NuxtLink>

        <p
          v-if="eventValid"
          class="line-clamp-1 text-lg md:text-base lg:line-clamp-none"
        >
          {{ formattedDateRange }}, {{ formattedLocation }}
        </p>
        <p v-else class="hidden md:block md:text-lg">
          Sign up to our newsletter to be notified as soon as the next event is
          announced
        </p>
      </div>

      <div class="hidden space-x-6 md:flex">
        <ButtonCommon
          v-if="eventValid"
          variant="hero"
          :link="eventPath"
          data-testid="read-more"
        >
          Read Now
        </ButtonCommon>
        <NuxtLink v-else to="/newsletters">
          <ButtonCommon variant="tertiary-dark" data-testid="read-more">
            Newsletter sign up
          </ButtonCommon>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
